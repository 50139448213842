import React, { useMemo } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { IPayoutFields } from 'src/types/generated/contentful'
import { InfoPopover, TextBlock, Type } from '@/components/common'
import { popupOverride } from '@/components/common/Popover/InfoPopover'
import Image from '@/components/contentful/Image'
import { ChevronRight } from '@/components/svg'
import useCampaign from '@/services/hooks/useCampaign'
import { formatMoney } from '@/utils'

interface Props
  extends Pick<
    IPayoutFields,
    | 'daysUntilProcessed'
    | 'dollarsRaisedHasDisclaimer'
    | 'infoROI'
    | 'logo'
    | 'percentROI'
    | 'slug'
    | 'testimonial'
    | 'title'
  > {
  className?: string
}

const PayoutCard: React.FC<Props> = ({
  className,
  daysUntilProcessed,
  dollarsRaisedHasDisclaimer,
  infoROI,
  logo,
  percentROI,
  slug,
  title,
}) => {
  const { campaign } = useCampaign(slug)
  const popover = useMemo(
    () =>
      infoROI.fields.text && (
        <TextBlock
          document={infoROI.fields.text}
          optionOverrides={popupOverride}
          className="!mb-0"
        />
      ),
    [infoROI.fields.text]
  )

  return (
    <div
      className={classNames(
        'rounded-2xl border border-core-gray-300 bg-core-white p-8',
        className
      )}
    >
      {logo ? (
        <Image image={logo} width={149} className="mb-6" />
      ) : (
        <Type as="h3" variant="heading-md" className="mb-6">
          {title}
        </Type>
      )}

      <Type as="div" variant="caption-sm" className="mb-4 flex items-end gap-1">
        <Type as="span" variant="title-lg">
          {percentROI}%
        </Type>
        return on investment
        {popover && (
          <InfoPopover content={popover} className="mb-1 text-core-oxide" />
        )}
      </Type>

      {!!campaign?.capitalRaised && (
        <Type as="p" variant="caption-md" className="text-core-gray-700">
          {formatMoney(campaign.capitalRaised, {
            zeroFractionDigits: true,
          })}{' '}
          raised{dollarsRaisedHasDisclaimer && '*'}
        </Type>
      )}
      {!!campaign?.investorsTotal && (
        <Type as="p" variant="caption-md" className="mb-4 text-core-gray-700">
          {campaign.investorsTotal.toLocaleString('en-US')} investors
        </Type>
      )}

      {daysUntilProcessed && (
        <Type as="p" variant="caption-md" className="mb-4 text-core-gray-900">
          Payout processed {daysUntilProcessed} days after the offering closed.
        </Type>
      )}

      <Link href={`/payout/${slug}`}>
        <Type
          as="span"
          variant="paragraph-sm"
          className="inline-flex items-center text-core-oxide"
        >
          Learn more <ChevronRight className="inline-block h-4 w-4" />
        </Type>
      </Link>
    </div>
  )
}

export default PayoutCard
