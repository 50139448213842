import React from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import InterstitialLink from '@/components/InterstitialLink'
import { Button, Type } from '@/components/common'
import { ArrowSquareOut } from '@/components/svg'

const DavidBanner: React.FC = () => {
  const bgMobile =
    'bg-[url("https://images.angelstudios.com/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1700609362/angel-funding/david_banner.jpg")]'
  const bgDesktop =
    'md:bg-[url("https://images.angelstudios.com/image/upload/v1700609362/angel-funding/david_banner.jpg")]'

  return (
    <div className="mb-12 w-full max-w-[1168px] px-4 md:mb-14">
      <div
        className={classNames(
          bgMobile,
          bgDesktop,
          'relative flex h-full min-h-[488px] flex-col justify-end overflow-hidden rounded-2xl bg-cover bg-top bg-no-repeat md:min-h-[256px] md:flex-row md:bg-contain'
        )}
      >
        <div className="flex flex-col bg-gradient-to-b from-transparent to-[#223588] to-50% px-4 pb-16 pt-2 md:grid md:w-full md:grid-cols-2 md:bg-none md:p-0 md:text-center">
          <div className="from-[#223588] from-40% to-transparent md:flex md:flex-col md:justify-center md:bg-gradient-to-r lg:from-50%">
            <Image
              alt="David"
              className="mb-4 w-[170px] md:mx-auto md:w-[200px] lg:w-[228px]"
              src="https://images.angelstudios.com/image/upload/v1700611358/angel-funding/david_logo.png"
              width={228}
              height={64}
            />
            <Type
              as="p"
              variant="title-lg"
              className="font-semibold text-core-white"
            >
              David - Feature Film
            </Type>
            <Type
              as="p"
              variant="caption-md"
              className="mb-4 text-core-gray-400"
            >
              <InterstitialLink domain="thedavidmovie.com">
                thedavidmovie.com
              </InterstitialLink>
            </Type>
          </div>

          <div className="from-[#223588] from-40% to-transparent md:flex md:flex-col md:items-center md:justify-center md:bg-gradient-to-l lg:from-50%">
            <Type
              as="p"
              variant="title-sm"
              className="text-core-gray-300 md:max-w-[12.25em] lg:max-w-[16em]"
            >
              After slaying a giant, a young shepherd boy relies upon God as he
              inspires a nation as their warrior, poet, prophet, and king.
            </Type>
          </div>
        </div>
        <InterstitialLink
          as="div"
          domain="thedavidmovie.com"
          className="absolute bottom-2 right-0 md:right-1/2 md:translate-x-1/2"
        >
          <Button
            variant="link"
            color="white"
            className="flex flex-nowrap items-center justify-center gap-1.5 px-7 py-4"
          >
            Learn More <ArrowSquareOut />
          </Button>
        </InterstitialLink>
      </div>
    </div>
  )
}

export default DavidBanner
