import React, { useEffect, useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  ISectionPayoutsFields,
  ISiteAssetsFields,
} from 'src/types/generated/contentful'
import { Button } from '@/components/common'
import FaqSection from '@/components/pages/landing/FaqSection'
import { Campaign } from '@/services/api/campaign'
import useAuth from '@/services/hooks/useAuth'
import useCampaigns from '@/services/hooks/useCampaigns'
import CampaignSection from './CampaignSection'
import DavidBanner from './DavidBanner'
import LandingPageHero from './LandingPageHero'
import PayoutsSection from './PayoutsSection'

// closing soonest to latest
const sortEstimateClosingSoonest = (a: Campaign, b: Campaign) => {
  // handle null cases
  if (b.hoursRemainingEstimate === null && a.hoursRemainingEstimate === null) {
    return 0
  } else if (a.hoursRemainingEstimate === null) {
    return 1
  } else if (b.hoursRemainingEstimate === null) {
    return -1
  }

  return a.hoursRemainingEstimate - b.hoursRemainingEstimate
}

export interface Props {
  allCampaigns: Campaign[]
  sectionPayouts: ISectionPayoutsFields
  siteAssets: ISiteAssetsFields
}

const LandingPage: React.FC<Props> = ({
  allCampaigns,
  sectionPayouts,
  siteAssets,
}) => {
  const { openModal: openAuthModal } = useAuth()
  const router = useRouter()
  const { campaigns = allCampaigns } = useCampaigns()

  useEffect(() => {
    if (router.query.auth) {
      openAuthModal()
    }
  }, [router, openAuthModal])

  const [currentlyFunding, testingWaters, offeringClosed] = useMemo(() => {
    const funding: Campaign[] = []
    const testing: Campaign[] = []
    const closed: Campaign[] = []

    campaigns.forEach((campaign) => {
      // hide all draft campaigns from visibility:
      if (campaign.draftMode) return

      if (campaign.status === 'closed' || campaign.status === 'archived') {
        closed.push(campaign)
      } else if (campaign.status === 'currently_funding') {
        funding.push(campaign)
      } else {
        testing.push(campaign)
      }
    })

    return [
      funding.sort(sortEstimateClosingSoonest),
      testing.sort(sortEstimateClosingSoonest),
      closed,
    ]
  }, [campaigns])

  return (
    <main className="h-full min-h-screen flex-grow bg-core-gray-100">
      <LandingPageHero />

      <div className="flex flex-col items-center bg-core-gray-100 pt-14 md:pt-20">
        <PayoutsSection {...sectionPayouts} />

        {!!currentlyFunding.length && (
          <CampaignSection
            campaigns={currentlyFunding}
            data-cy="currently-funding-group"
            title="Currently Funding"
          />
        )}

        <DavidBanner />

        {!!testingWaters.length && (
          <CampaignSection
            campaigns={testingWaters}
            data-cy="gauging-interest-group"
            title="Gauging Interest"
          />
        )}

        {!!offeringClosed.length && (
          <Link href="/previously-funded" className="mb-24 mt-10">
            <Button variant="secondary" pill>
              Previously Funded Projects
            </Button>
          </Link>
        )}
      </div>
      {siteAssets?.siteFaQs?.fields && (
        <div className="flex flex-col items-center pt-8">
          <FaqSection siteFaqs={siteAssets.siteFaQs?.fields} />
        </div>
      )}
    </main>
  )
}

export default LandingPage
