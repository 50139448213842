import React from 'react'
import classNames from 'classnames'
import { CampaignCard, Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'

interface Props {
  campaigns: Campaign[]
  title: string
  subtitle?: string
}

export const CampaignSection: React.FC<Props> = ({
  campaigns,
  title,
  subtitle,
  ...rest
}) => {
  return (
    <div className="mb-12 w-full max-w-[1168px] px-4 md:mb-14" {...rest}>
      <Type
        as="h2"
        variant="heading-sm"
        className="font-semibold md:mr-2 md:inline-block"
      >
        {title}
      </Type>
      {subtitle && (
        <Type
          as="p"
          variant={['title-xs', 'md:title-lg']}
          className="text-core-gray-700 md:inline-block"
        >
          {subtitle}
        </Type>
      )}
      <div
        className={classNames('mt-4 grid gap-4', {
          'sm:grid-cols-2': campaigns.length >= 2,
          'lg:grid-cols-3': campaigns.length >= 3,
        })}
      >
        {campaigns.map((campaign) => (
          <CampaignCard
            key={campaign.slug}
            campaign={campaign}
            layout={campaigns.length === 1 ? 'horizontal' : 'vertical'}
          />
        ))}
      </div>
    </div>
  )
}

export default CampaignSection
