import React from 'react'
import classNames from 'classnames'
import { Type } from '@/components/common'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import { IFaqSectionFields } from '../../../types/generated/contentful'

export interface Props {
  siteFaqs: IFaqSectionFields
}

export const FaqSection: React.FC<Props> = ({ siteFaqs }) => {
  const faq = {
    questions: siteFaqs.faqs.map((faq) => {
      return { answer: faq.fields.answer, question: faq.fields.question }
    }),
  }
  return (
    <div className="mb-8 w-full max-w-[1168px] px-4">
      <Type
        as="h2"
        variant="heading-sm"
        className="font-semibold md:mr-2 md:inline-block"
      >
        {siteFaqs.title}
      </Type>
      {siteFaqs.subtitle && (
        <Type
          as="p"
          variant={['title-xs', 'md:title-lg']}
          className="text-core-gray-700 md:inline-block"
        >
          {siteFaqs.subtitle}
        </Type>
      )}
      <div className={classNames('mt-6 w-full gap-6 overflow-hidden')}>
        <FAQ faq={faq} />
      </div>
    </div>
  )
}

export default FaqSection
