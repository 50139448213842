import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import classNames from 'classnames'
import { ISectionPayoutsFields } from 'src/types/generated/contentful'
import { CarouselNav, TextBlock, Type } from '@/components/common'
import useCarousel from '@/services/hooks/useCarousel/useCarousel'
import PayoutCard from './PayoutCard'

const disclaimerOverride = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
    return (
      <Type as="p" variant="footer" className="text-xs text-core-gray-600">
        {children}
      </Type>
    )
  },
}

const PayoutsSection: React.FC<ISectionPayoutsFields> = ({
  title,
  payouts,
  disclaimer,
}) => {
  const [
    scrollerRef,
    { canScrollBack, canScrollForward, scrollBack, scrollForward, orientation },
  ] = useCarousel<HTMLDivElement>()

  return (
    // reduce mb by 4 because TextBlock adds mb-4
    <div className="mx-auto mb-8 w-full max-w-[1168px] px-4 md:mb-10">
      <Type as="h2" variant="heading-sm" className="mb-4 font-semibold">
        {title}
      </Type>
      <div className="group relative">
        <CarouselNav
          active={canScrollBack}
          orientation={orientation}
          direction="back"
          onClick={scrollBack}
          className="hidden p-1 md:flex"
        />

        <CarouselNav
          active={canScrollForward}
          orientation={orientation}
          direction="forward"
          onClick={scrollForward}
          className="hidden p-1 md:flex"
        />

        <div
          ref={scrollerRef}
          className={classNames(
            '-mr-4 mb-4 flex gap-4',
            'hide-scrollbar snap-x snap-mandatory overflow-x-scroll overscroll-x-contain'
          )}
        >
          {payouts.map((payout) => (
            <PayoutCard
              key={payout.sys.id}
              {...payout.fields}
              className="min-w-[280px] flex-1 snap-start last:mr-4"
            />
          ))}
        </div>
      </div>
      <TextBlock document={disclaimer} optionOverrides={disclaimerOverride} />
    </div>
  )
}

export default PayoutsSection
